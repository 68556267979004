import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
// Import both logo variants
import darkLogo from './assets/images/sparkica-logo.svg'
import colorLogo from './assets/images/sparkica-logo-color.svg'
import { useTheme } from './context/ThemeContext'

function App() {
  const { isDarkTheme, toggleTheme } = useTheme()
  const [scrolled, setScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const portfolioRef = useRef<HTMLElement>(null)

  // Store scroll position before navigation
  const handlePortfolioClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const scrollPosition = window.scrollY
    sessionStorage.setItem('scrollPosition', scrollPosition.toString())
    window.location.href = e.currentTarget.href
  }

  // Restore scroll position when component mounts
  useEffect(() => {
    const storedPosition = sessionStorage.getItem('scrollPosition')
    if (storedPosition) {
      window.scrollTo(0, parseInt(storedPosition))
      sessionStorage.removeItem('scrollPosition')
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50
      setScrolled(isScrolled)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // Handle scroll position when navigating back from portfolio
  useEffect(() => {
    const handlePopState = () => {
      const hash = window.location.hash
      if (hash === '#portfolio' && portfolioRef.current) {
        // Use setTimeout to ensure the DOM is ready
        setTimeout(() => {
          portfolioRef.current?.scrollIntoView({ behavior: 'smooth' })
        }, 0)
      }
    }

    window.addEventListener('popstate', handlePopState)
    return () => window.removeEventListener('popstate', handlePopState)
  }, [])

  return (
    <div className={`min-h-screen ${isDarkTheme ? 'bg-[#1C1C1C] text-white' : 'bg-white text-[#1C1C1C]'} transition-colors duration-300`}>
      {/* Header */}
      <header className={`fixed w-full top-0 z-50 transition-all duration-300 ${isDarkTheme ? 'bg-[#1C1C1C]' : 'bg-white'} shadow-sm`}>
        <div className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            {/* Logo */}
            <a href="/" className="text-xl font-antonio flex items-center gap-2 relative z-10">
              <img 
                src={isDarkTheme ? darkLogo : colorLogo} 
                alt="Logo" 
                className="h-8 w-auto transition-opacity duration-300" 
              />
            </a>

            {/* Desktop Navigation */}
            <nav className="hidden md:block font-antonio relative z-10">
              <ul className="flex gap-8">
                <li><a href="#portfolio" className="hover:opacity-70 transition-opacity">Portfolio</a></li>
                <li><a href="#process" className="hover:opacity-70 transition-opacity">What I do</a></li>
                <li><a href="/blog" className="hover:opacity-70 transition-opacity">Blog</a></li>
                <li><a href="#about" className="hover:opacity-70 transition-opacity">About</a></li>
              </ul>
            </nav>

            <div className="flex items-center gap-4 relative z-10">
              {/* Theme Toggle */}
              <button
                onClick={toggleTheme}
                className={`p-2 rounded-full ${
                  isDarkTheme 
                    ? 'bg-white text-[#1C1C1C] hover:bg-opacity-90' 
                    : 'bg-[#1C1C1C] text-white hover:bg-opacity-90'
                } transition-all duration-300`}
                aria-label="Toggle theme"
              >
                {isDarkTheme ? '☀️' : '🌙'}
              </button>

              {/* Hamburger Menu Button */}
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="md:hidden p-2"
                aria-label="Toggle menu"
              >
                <div className="w-6 flex flex-col gap-1.5">
                  <span className={`block h-0.5 w-full transition-all duration-300 ${isDarkTheme ? 'bg-white' : 'bg-[#1C1C1C]'} ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
                  <span className={`block h-0.5 w-full transition-all duration-300 ${isDarkTheme ? 'bg-white' : 'bg-[#1C1C1C]'} ${isMenuOpen ? 'opacity-0' : ''}`}></span>
                  <span className={`block h-0.5 w-full transition-all duration-300 ${isDarkTheme ? 'bg-white' : 'bg-[#1C1C1C]'} ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
                </div>
              </button>
            </div>
          </div>

          {/* Mobile Navigation */}
          <nav 
            className={`md:hidden transition-all duration-300 overflow-hidden ${isMenuOpen ? 'max-h-48 mt-6' : 'max-h-0'}`}
          >
            <ul className="flex flex-col gap-4 font-antonio">
              <li>
                <a 
                  href="#portfolio" 
                  className="block py-2 hover:opacity-70 transition-opacity"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Portfolio
                </a>
              </li>
              <li>
                <a 
                  href="/blog" 
                  className="block py-2 hover:opacity-70 transition-opacity"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Blog
                </a>
              </li>
              <li>
                <a 
                  href="#about" 
                  className="block py-2 hover:opacity-70 transition-opacity"
                  onClick={() => setIsMenuOpen(false)}
                >
                  About
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section className="min-h-screen flex items-center pt-20 md:pt-24">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-8 md:gap-12">
            {/* Title Group */}
            <div className="flex flex-col gap-4 md:gap-8 md:max-w-[60%]">
              <h1 
                className={`font-antonio text-6xl md:text-[8vw] lg:text-[8vw] leading-[0.9] font-bold tracking-tight transition-transform duration-500 ${
                  scrolled ? 'animate-slide-out-left' : 'animate-slide-in-left'
                }`}
                style={{ animationFillMode: 'forwards' }}
              >
                Možganostroj
              </h1>
              <h2 
                className={`font-antonio text-2xl md:text-[4vw] lg:text-[4vw] leading-[0.9] font-bold tracking-tight transition-transform duration-500 ${
                  scrolled ? 'animate-slide-out-right' : 'animate-slide-in-right'
                }`}
                style={{ animationFillMode: 'forwards' }}
              >
                Beyond imagination
              </h2>
            </div>

            {/* Description */}
            <div className="md:self-start md:pt-4">
              <p className="text-lg md:text-xl opacity-80 max-w-xs">
                It's not your final destination, enjoy your stop while you're here.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Selected Work Section */}
      <section id="portfolio" ref={portfolioRef} className={`py-16 md:py-32 ${isDarkTheme ? '' : 'bg-neutral-100'}`}>
        <div className="container mx-auto px-6">
          <h2 className="font-antonio text-4xl font-medium mb-16">What I do when I'm procrastinating</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                id: 1,
                image: '/images/make-my-day/strip-dead-pixel.png',
                title: 'Make My Day Comics',
                category: 'cartoon'
              },
              {
                id: 2,
                image: '/images/stripgenerator/stripgenerator-prakticna-tetovaza.png',
                title: 'Stripgenerator Comics',
                category: 'stripgenerator'
              },
              {
                id: 3,
                image: '/images/drawings/best-friends.png',
                title: 'Drawings',
                category: 'drawing'
              }
            ].map((item) => (
              <Link 
                key={item.id} 
                to={`/portfolio?category=${item.category}`}
                onClick={handlePortfolioClick}
                className="relative overflow-hidden group"
              >
                <div className="aspect-square w-full relative">
                  <img 
                    src={item.image} 
                    alt={item.title}
                    className="w-full h-full object-cover filter grayscale hover:grayscale-0 transition-all duration-500"
                  />
                  {/* Frame overlay */}
                  <div className="absolute inset-0 pointer-events-none">
                    <div className="absolute top-[10%] right-0 w-full h-[80%] border-r-2 border-white"></div>
                    <div className="absolute bottom-[10%] left-0 w-full h-[80%] border-l-2 border-white"></div>
                    <div className="absolute top-0 right-[10%] h-full w-[80%] border-t-2 border-white"></div>
                    <div className="absolute bottom-0 left-[10%] h-full w-[80%] border-b-2 border-white"></div>
                  </div>
                  {/* Title overlay */}
                  <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 flex items-end justify-start p-6 transition-all duration-500">
                    <h3 className="font-antonio text-2xl text-white opacity-0 group-hover:opacity-100 transform translate-y-4 group-hover:translate-y-0 transition-all duration-500">
                      {item.title}
                    </h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="mt-16 text-center">
            <Link 
              to="/portfolio"
              onClick={handlePortfolioClick}
              className={`inline-flex items-center gap-2 px-8 py-4 rounded-full font-antonio text-lg transition-all ${
                isDarkTheme 
                  ? 'bg-white text-[#1C1C1C] hover:bg-opacity-90' 
                  : 'bg-[#1C1C1C] text-white hover:bg-opacity-90'
              }`}
            >
              Check all out
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </Link>
          </div>
        </div>
      </section>

      {/* Skills Section */}
      {/* <section className={`py-32 ${isDarkTheme ? 'bg-neutral-900' : 'bg-neutral-200'}`}>
        <div className="container mx-auto px-6">
          <h2 className="font-antonio text-4xl font-medium mb-16">My Skills</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div>
              <h3 className="font-antonio text-2xl font-medium mb-4">Web Design</h3>
              <p className="opacity-80">I create visually striking websites that are both user-friendly and aligned with your brand identity, ensuring an engaging online presence.</p>
            </div>
            <div>
              <h3 className="font-antonio text-2xl font-medium mb-4">UI/UX Design</h3>
              <p className="opacity-80">I specialize in crafting intuitive digital experiences that prioritize user satisfaction and retention, blending aesthetic appeal with seamless functionality.</p>
            </div>
            <div>
              <h3 className="font-antonio text-2xl font-medium mb-4">Branding</h3>
              <p className="opacity-80">From logo design to brand guidelines, I develop cohesive visual identities that leave a lasting impression and foster brand loyalty across all platforms.</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* Process Section */}
      <section id="process" className={`py-16 md:py-32 ${isDarkTheme ? '' : 'bg-neutral-100'}`}>
        <div className="container mx-auto px-6">
          <h2 className="font-antonio text-4xl font-medium mb-16">What I do when I'm NOT procrastinating</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {[
              {
                step: "01",
                title: "Facilitate Design sprints",
                description: "Organizing and facilitating design sprints to solve challenges and come up with solutions using design thinking."
              },
              {
                step: "02",
                title: "Co-organize community events",
                description: "Volunteering and co-organizing community events (conferences, workshops) to bring people together to learn, share and grow."
              },
              {
                step: "03",
                title: "Help local entrepreneurs",
                description: "Mentoring and helping local entrepreneurs to grow their businesses."
              }
            ].map((item) => (
              <div key={item.step}>
                <div className="font-antonio text-xl font-medium opacity-50 mb-2">{item.step}</div>
                <h3 className="font-antonio text-2xl font-medium mb-4">{item.title}</h3>
                <p className="opacity-80">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
{/*       <section id="testimonials" className={`py-32 ${isDarkTheme ? 'bg-neutral-900' : 'bg-neutral-200'}`}>
        <div className="container mx-auto px-6">
          <h2 className="font-antonio text-4xl font-medium mb-16">Testimonials</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {[
              {
                quote: "Working with this designer was a game-changer for our projects. Their creativity and attention to detail brought our vision to life in ways we couldn't have imagined.",
                author: "John Doe",
                position: "Managing Director at Apple"
              },
              {
                quote: "They were professional, responsive, and truly invested in our success. The entire process was seamless, and we couldn't be happier with the final product.",
                author: "Sofia Carter",
                position: "Marketing Manager at Twitter"
              }
            ].map((item, index) => (
              <div key={index} className={`${isDarkTheme ? 'bg-neutral-800' : 'bg-neutral-300'} p-8 rounded-lg`}>
                <p className="text-lg mb-8 opacity-80">{item.quote}</p>
                <div>
                  <div className="font-medium">{item.author}</div>
                  <div className="opacity-50">{item.position}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* About Section */}
      <section id="about" className={`py-16 md:py-32 ${isDarkTheme ? '' : 'bg-neutral-100'}`}>
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row gap-12 items-start">
            {/* Text Content */}
            <div className="md:w-1/2">
              <div 
                className="transform perspective-[1200px] transition-all duration-700"
                style={{ 
                  willChange: 'transform',
                  transformStyle: 'preserve-3d'
                }}
              >
                <h2 className="font-antonio text-4xl font-medium mb-8 animate-fade-in-top">About Sparkica & Možganostroj</h2>
              </div>
              <div className="space-y-6">
                <p className="text-lg opacity-80 animate-fade-in-top" style={{ animationDelay: '0.2s' }}>
                  Možganostroj (Brainmachine): Started as an experiment. I wanted to share my sparks of creativity with you.
                </p>
                <p className="text-lg opacity-80 animate-fade-in-top" style={{ animationDelay: '0.4s' }}>
                  Sparkica: 

                  Ordinary woman sometimes doing extra-ordinary things
<br/><br/>
When I start to believe that I am special snowflake (just as anyone else is btw), I hit into obstacles and then I'm humbled into realizing I am just and ordinary person.
<br/><br/>
But this is good. Why? Ordinary gets shit done.
<br/><br/> 
Why do we get the shit done? Because we figure out that things don't get done by themselves and that waiting for princes (or princesses) and heroes (heroins) only makes people getting old.
                </p>
                <p className="text-lg opacity-80 animate-fade-in-top" style={{ animationDelay: '0.6s' }}>
                  Courious and restless soul, bender of my own boundaries, defender of open-mindedness, mistress of humor, satire and fiction in general. Sporty-and-active-life tribe member, adrenaline-seeker and a professional fear-fighter!
                </p>
                <p className="text-lg opacity-80 animate-fade-in-top" style={{ animationDelay: '0.8s' }}>
                  I've learned many important lessons in life, one of the most important ones being: you always have a choice, even if you can't recognize it at first.
                </p>
              </div>
            </div>
            
            {/* Image */}
            <div className="md:w-1/2 mt-8 md:mt-0">
              <div 
                className="relative overflow-hidden rounded-lg border border-neutral-300 animate-fade-in-bottom"
                style={{ animationDelay: '0.4s' }}
              >
                <div className="aspect-[4/5] w-full">
                  <img 
                    src="/images/futuristic-princess-warrior.png" 
                    alt="Sparkica" 
                    className="w-full h-full object-cover filter grayscale hover:grayscale-0 transition-all duration-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className={`py-16 md:py-32 ${isDarkTheme ? 'bg-neutral-900' : 'bg-neutral-200'}`}>
        <div className="container mx-auto px-6 text-center">
          <h2 className="font-antonio text-4xl font-medium mb-8">Interested In Working Together?</h2>
          <div className="flex justify-center gap-4">
            <button 
              onClick={() => {
                navigator.clipboard.writeText('mateja@mozganostroj.com')
                  .then(() => {
                    const button = document.activeElement as HTMLButtonElement;
                    const originalText = button.textContent;
                    button.textContent = 'Copied!';
                    setTimeout(() => {
                      button.textContent = 'Copy Email';
                    }, 2000);
                  })
                  .catch(err => console.error('Failed to copy:', err));
              }}
              className={`${isDarkTheme ? 'bg-white text-black' : 'bg-[#1C1C1C] text-white'} px-6 py-3 rounded-full hover:opacity-90 transition-opacity`}
            >
              Copy Email
            </button>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className={`py-8 border-t ${isDarkTheme ? 'border-neutral-800' : 'border-neutral-200'}`}>
        <div className="container mx-auto px-6 flex justify-between items-center">
          <div className="font-antonio text-xl font-medium">S.M.</div>
          <div className="opacity-50">© 2024 - All Rights Reserved.</div>
        </div>
      </footer>
    </div>
  )
}

export default App 