import React, { useState, useRef, useEffect } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useTheme } from '../context/ThemeContext'

interface PortfolioItem {
  id: number
  image: string
  title: string
  category: 'cartoon' | 'drawing' | 'stripgenerator'
}

const portfolioItems: PortfolioItem[] = [
  // Make My Day Comics
  {
    id: 1,
    image: '/images/make-my-day/strip-dead-pixel.png',
    title: 'Dead Pixel',
    category: 'cartoon'
  },
  {
    id: 2,
    image: '/images/make-my-day/strip-arachnophobia.png',
    title: 'Arachnophobia',
    category: 'cartoon'
  },
  {
    id: 3,
    image: '/images/make-my-day/strip-dragon.png',
    title: 'Dragon',
    category: 'cartoon'
  },
  {
    id: 4,
    image: '/images/make-my-day/strip-selftalk.png',
    title: 'Self Talk',
    category: 'cartoon'
  },
  {
    id: 5,
    image: '/images/make-my-day/Ambivalent_bond.jpg',
    title: 'Ambivalent Bond',
    category: 'cartoon'
  },
  {
    id: 6,
    image: '/images/make-my-day/Bad_News_Monday.jpg',
    title: 'Bad News Monday',
    category: 'cartoon'
  },
  {
    id: 7,
    image: '/images/make-my-day/Belonging.jpg',
    title: 'Belonging',
    category: 'cartoon'
  },
  {
    id: 8,
    image: '/images/make-my-day/BestFriend.jpg',
    title: 'Best Friend',
    category: 'cartoon'
  },
  {
    id: 9,
    image: '/images/make-my-day/BreedingFriends.jpg',
    title: 'Breeding Friends',
    category: 'cartoon'
  },
  {
    id: 10,
    image: '/images/make-my-day/Dog_and_hydrant.jpg',
    title: 'Dog and Hydrant',
    category: 'cartoon'
  },
  {
    id: 11,
    image: '/images/make-my-day/Dragon.jpg',
    title: 'Dragon Story',
    category: 'cartoon'
  },
  {
    id: 12,
    image: '/images/make-my-day/Earthlings.jpg',
    title: 'Earthlings',
    category: 'cartoon'
  },
  {
    id: 13,
    image: '/images/make-my-day/Fish-n-chips.jpg',
    title: 'Fish & Chips',
    category: 'cartoon'
  },
  {
    id: 14,
    image: '/images/make-my-day/Fish_language.jpg',
    title: 'Fish Language',
    category: 'cartoon'
  },
  {
    id: 15,
    image: '/images/make-my-day/Lost_it.jpg',
    title: 'Lost It',
    category: 'cartoon'
  },
  {
    id: 16,
    image: '/images/make-my-day/MonkeyBusiness.jpg',
    title: 'Monkey Business',
    category: 'cartoon'
  },
  {
    id: 17,
    image: '/images/make-my-day/Nuts.jpg',
    title: 'Nuts',
    category: 'cartoon'
  },
  {
    id: 18,
    image: '/images/make-my-day/Pervert.jpg',
    title: 'Pervert',
    category: 'cartoon'
  },
  {
    id: 19,
    image: '/images/make-my-day/SpiderNotSpider.jpg',
    title: 'Spider Not Spider',
    category: 'cartoon'
  },
  {
    id: 20,
    image: '/images/make-my-day/TalkToSelf.jpg',
    title: 'Talk To Self',
    category: 'cartoon'
  },
  {
    id: 21,
    image: '/images/make-my-day/Tenses.jpg',
    title: 'Tenses',
    category: 'cartoon'
  },
  {
    id: 22,
    image: '/images/make-my-day/Transparency.jpg',
    title: 'Transparency',
    category: 'cartoon'
  },
  {
    id: 23,
    image: '/images/make-my-day/YoMamaPoor.jpg',
    title: 'Yo Mama Poor',
    category: 'cartoon'
  },
  {
    id: 24,
    image: '/images/make-my-day/dead.jpg',
    title: 'Dead',
    category: 'cartoon'
  },
  {
    id: 25,
    image: '/images/make-my-day/the_silence_of_the_lambs1.jpg',
    title: 'The Silence of the Lambs',
    category: 'cartoon'
  },
  // Stripgenerator Comics
  {
    id: 26,
    image: '/images/stripgenerator/stripgenerator-prakticna-tetovaza.png',
    title: 'Praktična tetovaža',
    category: 'stripgenerator'
  },
  {
    id: 27,
    image: '/images/stripgenerator/stripgenerator-ukinitev-spanja.png',
    title: 'Ukinitev spanja',
    category: 'stripgenerator'
  },
  {
    id: 28,
    image: '/images/stripgenerator/stripgenerator-stara-sola.png',
    title: 'Stara šola',
    category: 'stripgenerator'
  },
  {
    id: 29,
    image: '/images/stripgenerator/stripgenerator-ribja.png',
    title: 'Ribja',
    category: 'stripgenerator'
  },
  {
    id: 30,
    image: '/images/stripgenerator/stripgenerator-disleksija.png',
    title: 'Disleksija',
    category: 'stripgenerator'
  },
  {
    id: 31,
    image: '/images/stripgenerator/stripgenerator-zupnikovo-iskanje.png',
    title: 'Župnikovo iskanje',
    category: 'stripgenerator'
  },
  {
    id: 32,
    image: '/images/stripgenerator/stripgenerator-best-friend.png',
    title: 'Best Friend',
    category: 'stripgenerator'
  },
  {
    id: 33,
    image: '/images/stripgenerator/stripgenerator-love.png',
    title: 'Love',
    category: 'stripgenerator'
  },
  {
    id: 34,
    image: '/images/stripgenerator/stripgenerator-gladovna-stavka.png',
    title: 'Gladovna stavka',
    category: 'stripgenerator'
  },
  {
    id: 35,
    image: '/images/stripgenerator/stripgenerator-nogometna-strategija.png',
    title: 'Nogometna strategija',
    category: 'stripgenerator'
  },
  {
    id: 36,
    image: '/images/stripgenerator/stripgenerator-nova-disciplina.png',
    title: 'Nova disciplina',
    category: 'stripgenerator'
  },
  {
    id: 37,
    image: '/images/stripgenerator/stripgenerator-potekla-licenca.png',
    title: 'Potekla licenca',
    category: 'stripgenerator'
  },
  {
    id: 38,
    image: '/images/stripgenerator/stripgenerator-tetovaza.png',
    title: 'Tetovaža',
    category: 'stripgenerator'
  },
  {
    id: 39,
    image: '/images/stripgenerator/geeky-party.png',
    title: 'Geeky Party',
    category: 'stripgenerator'
  },
  // Drawings
  {
    id: 40,
    image: '/images/drawings/best-friends.png',
    title: 'Best Friends',
    category: 'drawing'
  },
  {
    id: 41,
    image: '/images/drawings/Woody.jpg',
    title: 'Woody',
    category: 'drawing'
  },
  {
    id: 42,
    image: '/images/drawings/Colorization.jpg',
    title: 'Colorization',
    category: 'drawing'
  },
  {
    id: 43,
    image: '/images/drawings/Apple heart or hearty apple.jpg',
    title: 'Apple Heart',
    category: 'drawing'
  },
  {
    id: 44,
    image: '/images/drawings/Lost and found.jpg',
    title: 'Lost and Found',
    category: 'drawing'
  },
  {
    id: 45,
    image: '/images/drawings/Landscape.jpg',
    title: 'Landscape',
    category: 'drawing'
  },
  {
    id: 46,
    image: '/images/drawings/Daydreaming.jpg',
    title: 'Daydreaming',
    category: 'drawing'
  },
  {
    id: 47,
    image: '/images/drawings/img_1237.jpg',
    title: 'Portrait Study',
    category: 'drawing'
  },
  {
    id: 48,
    image: '/images/drawings/portrait_02.jpg',
    title: 'Portrait 02',
    category: 'drawing'
  },
  {
    id: 49,
    image: '/images/drawings/samota_cb.jpg',
    title: 'Samota',
    category: 'drawing'
  },
  {
    id: 50,
    image: '/images/drawings/portfolio023.jpg',
    title: 'Portfolio Study 23',
    category: 'drawing'
  },
  {
    id: 51,
    image: '/images/drawings/zeko.jpg',
    title: 'Zeko',
    category: 'drawing'
  },
  {
    id: 52,
    image: '/images/drawings/najljubsa.jpg',
    title: 'Najljubša',
    category: 'drawing'
  },
  {
    id: 53,
    image: '/images/drawings/prerijski_kuza.jpg',
    title: 'Prerijski Kuža',
    category: 'drawing'
  },
  {
    id: 54,
    image: '/images/drawings/portfolio079.jpg',
    title: 'Portfolio Study 79',
    category: 'drawing'
  },
  {
    id: 55,
    image: '/images/drawings/portfolio002.jpg',
    title: 'Portfolio Study 02',
    category: 'drawing'
  },
  {
    id: 56,
    image: '/images/drawings/erik02.jpg',
    title: 'Erik 02',
    category: 'drawing'
  },
  {
    id: 57,
    image: '/images/drawings/erik03.jpg',
    title: 'Erik 03',
    category: 'drawing'
  },
  {
    id: 58,
    image: '/images/drawings/portfolio054.jpg',
    title: 'Portfolio Study 54',
    category: 'drawing'
  },
  {
    id: 59,
    image: '/images/drawings/portfolio063.jpg',
    title: 'Portfolio Study 63',
    category: 'drawing'
  },
  {
    id: 60,
    image: '/images/drawings/rapperboy.jpg',
    title: 'Rapper Boy',
    category: 'drawing'
  },
  {
    id: 61,
    image: '/images/drawings/rapperboy2.jpg',
    title: 'Rapper Boy 2',
    category: 'drawing'
  },
  {
    id: 62,
    image: '/images/drawings/portfolio053.jpg',
    title: 'Portfolio Study 53',
    category: 'drawing'
  },
  {
    id: 63,
    image: '/images/drawings/portfolio001.jpg',
    title: 'Portfolio Study 01',
    category: 'drawing'
  },
  {
    id: 64,
    image: '/images/drawings/deklica-in-decek.jpg',
    title: 'Deklica in Deček',
    category: 'drawing'
  },
  {
    id: 65,
    image: '/images/drawings/decek-in-kuza.jpg',
    title: 'Deček in Kuža',
    category: 'drawing'
  },
  {
    id: 66,
    image: '/images/drawings/portfolio005.jpg',
    title: 'Portfolio Study 05',
    category: 'drawing'
  },
  {
    id: 67,
    image: '/images/drawings/erik04.jpg',
    title: 'Erik 04',
    category: 'drawing'
  },
  {
    id: 68,
    image: '/images/drawings/kokeshi_girl.jpg',
    title: 'Kokeshi Girl',
    category: 'drawing'
  },
  {
    id: 69,
    image: '/images/drawings/kokeshi_001_obarvana.jpg',
    title: 'Kokeshi Colored',
    category: 'drawing'
  },
  {
    id: 70,
    image: '/images/drawings/deklica_z_zerjavi.jpg',
    title: 'Deklica z Žerjavi',
    category: 'drawing'
  },
  {
    id: 71,
    image: '/images/drawings/rozast_obi_2.jpg',
    title: 'Rožast Obi',
    category: 'drawing'
  },
  {
    id: 72,
    image: '/images/drawings/decek_s_trakom.jpg',
    title: 'Deček s Trakom',
    category: 'drawing'
  },
  {
    id: 73,
    image: '/images/drawings/erik07.jpg',
    title: 'Erik 07',
    category: 'drawing'
  },
  {
    id: 74,
    image: '/images/drawings/erik08.jpg',
    title: 'Erik 08',
    category: 'drawing'
  }
]

function Portfolio() {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialCategory = searchParams.get('category') as PortfolioItem['category'] || 'stripgenerator'
  const [selectedCategory, setSelectedCategory] = useState<'all' | PortfolioItem['category']>(initialCategory)
  const { isDarkTheme } = useTheme()
  const [selectedImage, setSelectedImage] = useState<PortfolioItem | null>(null)
  const dialogRef = useRef<HTMLDialogElement>(null)
  const navigate = useNavigate()

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const filteredItems = selectedCategory === 'all' 
    ? portfolioItems 
    : portfolioItems.filter(item => item.category === selectedCategory)

  const handleCategoryChange = (category: 'all' | PortfolioItem['category']) => {
    setSelectedCategory(category)
    setSearchParams(category === 'all' ? {} : { category })
  }

  const handleImageClick = (item: PortfolioItem) => {
    setSelectedImage(item)
    dialogRef.current?.showModal()
  }

  const handleCloseDialog = () => {
    dialogRef.current?.close()
    setSelectedImage(null)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!selectedImage || !dialogRef.current?.open) return

    const currentIndex = filteredItems.findIndex(item => item.id === selectedImage.id)
    
    switch (e.key) {
      case 'ArrowLeft':
        e.preventDefault()
        if (currentIndex > 0) {
          setSelectedImage(filteredItems[currentIndex - 1])
        }
        break
      case 'ArrowRight':
        e.preventDefault()
        if (currentIndex < filteredItems.length - 1) {
          setSelectedImage(filteredItems[currentIndex + 1])
        }
        break
      case 'Escape':
        handleCloseDialog()
        break
    }
  }

  // Add and remove keyboard event listener
  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [selectedImage, filteredItems]) // Re-add listener when these dependencies change

  return (
    <div className={`min-h-screen ${isDarkTheme ? 'bg-[#1C1C1C] text-white' : 'bg-white text-[#1C1C1C]'}`}>
      {/* Navigation */}
      <nav className="fixed top-0 left-0 right-0 z-50 bg-opacity-90 backdrop-blur-sm" style={{ backgroundColor: isDarkTheme ? 'rgba(28, 28, 28, 0.9)' : 'rgba(255, 255, 255, 0.9)' }}>
        <div className="container mx-auto px-6 py-4">
          <button 
            onClick={() => navigate(-1)}
            className="font-antonio text-lg hover:opacity-70 transition-opacity inline-flex items-center gap-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            Back to Home
          </button>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="pt-32 pb-16">
        <div className="container mx-auto px-6">
          <h1 className="font-antonio text-6xl font-medium mb-8">Portfolio</h1>
          <p className="text-lg opacity-80 max-w-2xl">
            A collection of my artwork, including cartoons, drawings, and other creative pieces.
          </p>
        </div>
      </section>

      {/* Filter Section */}
      <section className="pb-16">
        <div className="container mx-auto px-6">
          <div className="flex gap-4 font-antonio">
            {['all', 'cartoon', 'drawing', 'stripgenerator'].map((category) => (
              <button
                key={category}
                onClick={() => handleCategoryChange(category as typeof selectedCategory)}
                className={`px-4 py-2 rounded-full transition-all ${
                  selectedCategory === category 
                    ? isDarkTheme 
                      ? 'bg-white text-[#1C1C1C]' 
                      : 'bg-[#1C1C1C] text-white'
                    : 'opacity-60 hover:opacity-100'
                }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </section>

      {/* Gallery Section */}
      <section className="pb-32">
        <div className="container mx-auto px-6">
          <div className="columns-1 md:columns-2 lg:columns-3 gap-8 space-y-8">
            {filteredItems.map((item) => (
              <div 
                key={item.id} 
                className="break-inside-avoid cursor-pointer"
                onClick={() => handleImageClick(item)}
              >
                <div className="relative overflow-hidden group">
                  <img 
                    src={item.image} 
                    alt={item.title}
                    className="w-full h-auto object-cover filter grayscale hover:grayscale-0 transition-all duration-500"
                  />
                  {/* Frame overlay */}
                  <div className="absolute inset-0 pointer-events-none">
                    <div className="absolute top-[10%] right-0 w-full h-[80%] border-r-2 border-white"></div>
                    <div className="absolute bottom-[10%] left-0 w-full h-[80%] border-l-2 border-white"></div>
                    <div className="absolute top-0 right-[10%] h-full w-[80%] border-t-2 border-white"></div>
                    <div className="absolute bottom-0 left-[10%] h-full w-[80%] border-b-2 border-white"></div>
                  </div>
                  {/* Title overlay */}
                  <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 flex items-end justify-start p-6 transition-all duration-500">
                    <h3 className="font-antonio text-2xl text-white opacity-0 group-hover:opacity-100 transform translate-y-4 group-hover:translate-y-0 transition-all duration-500">
                      {item.title}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Image Dialog */}
      <dialog
        ref={dialogRef}
        className="backdrop:bg-black backdrop:bg-opacity-75 bg-transparent p-0 max-w-[95vw] max-h-[95vh] w-fit h-fit"
        onClick={handleCloseDialog}
      >
        {selectedImage && (
          <div className="relative bg-black bg-opacity-90 p-8" onClick={e => e.stopPropagation()}>
            <div className="flex justify-between items-center mb-6">
              <h3 className="font-antonio text-3xl text-white">
                {selectedImage.title}
              </h3>
              <div className="flex items-center gap-4">
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    const currentIndex = filteredItems.findIndex(item => item.id === selectedImage.id)
                    if (currentIndex > 0) {
                      setSelectedImage(filteredItems[currentIndex - 1])
                    }
                  }}
                  className="text-white hover:text-gray-300 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={filteredItems.findIndex(item => item.id === selectedImage.id) === 0}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    const currentIndex = filteredItems.findIndex(item => item.id === selectedImage.id)
                    if (currentIndex < filteredItems.length - 1) {
                      setSelectedImage(filteredItems[currentIndex + 1])
                    }
                  }}
                  className="text-white hover:text-gray-300 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={filteredItems.findIndex(item => item.id === selectedImage.id) === filteredItems.length - 1}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
                <button
                  onClick={handleCloseDialog}
                  className="text-white hover:text-gray-300 transition-colors ml-4"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <img
              src={selectedImage.image}
              alt={selectedImage.title}
              className="max-w-[85vw] max-h-[80vh] object-contain mx-auto"
            />
          </div>
        )}
      </dialog>
    </div>
  )
}

export default Portfolio 